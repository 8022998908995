<template>
  <div>
    <a-form-model :colon="false" @submit.prevent="$emit('submit')" spellcheck="false" ref="form" :model="form" :rules="rules">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col lg="12">
               <a-form-model-item
                label="Title"
                name="title"
                prop="title">
                <a-input :disabled="loading" required v-model="form.title" />
              </a-form-model-item>
            </b-col>
            <b-col lg="12">
              <a-form-model-item
                label="Domain"
                name="domain"
                prop="domain">
                <a-input :disabled="loading" required v-model="form.domain" />
              </a-form-model-item>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <a-form-model-item
                class="mb-0"
                label="Users"
                name="users"
                prop="users">
                <a-auto-complete
                  v-model="userSearchText"
                  :filter-option="filterOption"
                  @search="searchUsers"
                  @change="onChange"
                  @select="selectUser"
                  allow-clear
                  :data-source="userDataSource"
                  placeholder="Search user">
                  <template slot="dataSource">
                    <a-select-option v-for="user in userDataSource" :key="user.id" :value="user.id">
                      {{ user.full_name }}
                    </a-select-option>
                  </template>
                </a-auto-complete>
                <div class="px-3 card mt-2" :class="type === 'create' ? 'pb-3' : ''">
                <a-table
                  :loading="userDataLoading"
                  :columns="userColumns"
                  :dataSource="portalUsers"
                  :class="$style.table"
                  @change="tableChanged">
                  <template slot="fullName" slot-scope="full_name">
                    {{ full_name }}
                  </template>
                  <template slot="action" slot-scope="action, item">
                    <div>
                      <button title="Delete" @click="showDeleteUserConfirm(item.id)" type="button" class="btn btn-sm btn-light">
                        <i class="fe fe-trash" />
                      </button>
                    </div>
                  </template>
                  <template slot="portals" slot-scope="portals">
                    {{ portals.map((portal) => {
                      return portal.title
                    }).join(', ') }}
                  </template>
                </a-table>
                <div v-if="type === 'edit'" :class="$style.tableFooter" class="text-center text-lg-left pb-4 pb-lg-0">
                  <span class="text-muted">Showing <b>{{ portalUsers.length }}</b> of <b>{{ new Intl.NumberFormat().format(userPagination.total) }}</b> Users</span>
                </div>
              </div>
              </a-form-model-item>
            </b-col>
          </b-row>
          <div class="text-right">
            <b-button :disabled="loading" variant="primary" @click="submit()">
              {{ type === 'create' ? 'Add New Portal' : 'Save Changes' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </a-form-model>

    <!-- Media Finder for Featured/Hero Image -->
    <b-modal hide-footer id="media-finder" size="xl" body-class="p-0 bg-light" centered title="Add Media">
      <MediaFinder :current="[form.image_media_id]" v-on:insert="applyMedia" />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MediaFinder from '../media/finder.vue'
import { Modal } from 'ant-design-vue'

export default {
  name: 'mainForm',
  props: {
    type: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      userSearchText: '',
      userDataSource: [],
      userPagination: {},
      portalUsers: [],
      showPassword: false,
      rules: {
        title: [
          {
            required: true,
            message: 'Please input title',
          },
        ],
        domain: [
          {
            required: true,
            message: 'Please input domain',
          },
        ],
      },
    }
  },
  created() {
    const vm = this
    if (vm.type === 'edit') {
      vm.$store.dispatch('user/FETCH', {
        portal_id: vm.$route.params.id,
        perPage: 99999,
      }).then((res) => {
        vm.portalUsers = res.data.data.map((user) => {
          return {
            ...user,
            key: user.id,
          }
        })
        const pagination = {
          current: Number(res.data.page),
          total: Number(res.data.total),
        }
        vm.userPagination = pagination
      })
    }
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('portal', {
      form: state => state.form,
    }),
    ...mapState('user', {
      users: state => state.data,
    }),
    userColumns() {
      // const vm = this
      const columns = [
        {
          title: 'Full Name',
          dataIndex: 'full_name',
          width: '30%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'fullName' },
        },
        {
          title: 'Username',
          dataIndex: 'username',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'username' },
        },
        {
          dataIndex: 'action',
          className: 'text-right',
          scopedSlots: { customRender: 'action' },
          fixed: 'right',
        },
      ]
      return columns
    },
    loading() {
      return this.$store.state.portal.loading
    },
    userDataLoading() {
      return this.$store.state.user.loading
    },
  },
  methods: {
    submit() {
      const vm = this

      vm.$refs.form.validate(valid => {
        if (valid) {
          vm.$emit('submit')
        } else {
          return false
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    searchUsers(value) {
      const vm = this
      vm.$store.dispatch('user/FETCH', {
        active: 1,
        q: value,
        portal_id: vm.settings.activePortal,
      }).then((res) => {
        vm.userDataSource = res.data.data.map((user) => {
          return {
            ...user,
            key: user.id,
          }
        })
      })
    },
    onChange(value) {
      // console.log(`changed to ${this.value}`)
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    // Media
    removeMedia() {
      const vm = this
      vm.$set(vm.form, 'image_media_id', '')
      vm.$set(vm.form, 'image_media', {})
    },
    mediaFinderOpen() {
      const vm = this

      vm.$bvModal.show('media-finder')
    },
    applyMedia(media, medias) {
      // console.log(media, medias)
      const vm = this
      vm.$set(vm.form, 'image_media', media)
      vm.$set(vm.form, 'image_media_id', media.id)
      vm.$bvModal.hide('media-finder')
    },
    tableChanged(pagination, filters, sorter) {
      // console.log('table changed', filters)
      // const vm = this
      // vm.fetch({
      //   page: pagination.current,
      //   perPage: pagination.pageSize,
      // })
    },
    selectUser(value) {
      const vm = this
      // Find selected user by id (value) from userDataSource
      const selectedUser = vm.userDataSource.find((user) => user.id === value)
      // Push selected user to portalUsers (for data table)
      vm.portalUsers.push(selectedUser)
      // Clear all data
      vm.$set(vm, 'userSearchText', '')
      vm.$set(vm, 'userDataSource', [])
    },
    showDeleteUserConfirm(id) {
      const vm = this
      Modal.confirm({
        title: 'Are you sure remove user from this portal?',
        content: 'This user will be remove from this portal',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          const index = vm.portalUsers.map((user) => {
            return user.id
          }).indexOf(id)
          vm.portalUsers.splice(index, 1)
          vm.$notification.success({
            message: 'Remove User',
            description: 'Successfully Remove User.',
          })
        },
        onCancel() {},
      })
    },
  },
  watch: {
    portalUsers: function(portalUsers) {
      const vm = this
      vm.$set(vm.form, 'users', portalUsers.map((user) => {
        return user.id
      }))
    },
  },
  components: {
    MediaFinder,
  },
  destroyed() {
    this.$store.commit('category/CLEAR_DATA')
    this.$store.commit('category/CLEAR_FORM')
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/mixins";

.table {
  td {
    vertical-align: top;

    h5 {
      font-size: 16px;
      margin-bottom: 2px;
    }
  }
  &Footer {
    @include media-breakpoint-up(lg) {
      margin-top: -52px;
      margin-bottom: 12px;
    }
  }
}
</style>
